import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import UserHeader from '../../../components/Auth/Header'
import Item from '../../../components/Auth/SliderView/MainSlider/Item'
import Slider from '../../../components/Auth/SliderView/MainSlider/Slider'
import Loading from '../../../components/Loading'
import { Video } from '../../../contexts/video'
import api from '../../../services/api'

const SubCategory = (props: any) => {
  const [movies, setMovies] = useState<any>([])

  const history = useHistory()

  function chunkArray (myArray: any[], size: number) {
    const results = []

    while (myArray.length) {
      results.push(myArray.splice(0, size))
    }

    return results
  }

  async function searchMovieById (SubCategoryId: any) {
    const responseSubcategories: any = await api.postMethod('sub_categories_videos', { sub_category_id: SubCategoryId })

    if (!responseSubcategories.data.success) {
      history.push('/logout')
      return
    }

    let data = []

    if (process.env.REACT_APP_ENV === 'PROD') {
      data = responseSubcategories.data.data.videos.map((movie: any) => {
        return movie.in_production && movie
      }).filter((m: any) => m)
    } else {
      data = responseSubcategories.data.data.videos.map((movie: any) => {
        return movie.in_test && movie
      }).filter((m: any) => m)
    }

    const result = chunkArray(data, 5)
    setMovies(result)
  }

  useEffect(() => {
    searchMovieById(props.match.params.id)
  }, [props.match.params.id])

  if (!movies) {
    return <Loading />
  }

  return (
    <>
      <UserHeader />
      <div className="mt-5" />
      <div className="main-slidersec">
        <h3 className="heading-3">{props.match.params.name}</h3>

        {movies.map((res: any) => (
          <Slider key={res.index}>
            <div style={{ margin: '0.1vmax 0' }}>
              {res.map((movie: Video, i: number) => (
                <Item movie={movie} key={movie.admin_video_id} index={i}>
                  item1
                </Item>
              ))}
            </div>
          </Slider>
        ))}
      </div>
    </>
  )
}

export default SubCategory
