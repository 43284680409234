/* eslint-disable react/prop-types */
import React, { useState } from 'react'
import cx from 'classnames'
import SliderContext from './context'
import Content from './Content'
import SlideButton from './SlideButton'
import SliderWrapper from './SliderWrapper'
import useSliding from './useSliding'
import useSizeElement from './useSizeElement'
import './Slider.scss'

const Slider = (props) => {
  const [currentSlide, setCurrentSlide] = useState(props.activeSlide)
  const [viewAll] = useState(props.viewAll)
  const { width, elementRef } = useSizeElement()
  const {
    handlePrev,
    handleNext,
    slideProps,
    containerRef,
    hasNext,
    hasPrev
  } = useSliding(width, React.Children.count(props.children))

  const handleSelect = movie => {
    setCurrentSlide(movie)
  }

  const handleClose = () => {
    setCurrentSlide(null)
    localStorage.removeItem('current_video_id')
  }

  const contextValue = {
    onSelectSlide: handleSelect,
    onCloseSlide: handleClose,
    elementRef,
    currentSlide
  }
  console.log(viewAll)
  return (
    <SliderContext.Provider value={contextValue}>
      <SliderWrapper>
        <div className={cx('slider', { 'slider--open': currentSlide != null })}>
          <div ref={containerRef} className="slider__container" {...slideProps}>
            {props.children}
          </div>
        </div>
        {hasPrev && <SlideButton onClick={handlePrev} type="prev" />}
        {hasNext && <SlideButton onClick={handleNext} type="next" />}
      </SliderWrapper>
      {currentSlide && <Content movie={currentSlide} onClose={handleClose} />}
    </SliderContext.Provider>
  )
}

export default Slider
