import React, { /* useEffect, useState */ } from 'react'
import { Link } from 'react-router-dom'
// import { t } from 'react-multi-lang'
// import { useHistory } from 'react-router-dom'
// import { toast } from 'react-toastify'

import Header from '../../../components/Auth/Header'
import { ButtonOrange } from '../../../components/Buttons'
// import Loading from '../../../components/Loading'
// import useAuth from '../../../hooks/useAuth'
// import api from '../../../services/api'
// import axios from 'axios'

import {
  ContainerPlan
} from './styled'

const Plan = () => {
  /* const { user } = useAuth()
  const [plans, setPlans] = useState([])

  const history = useHistory()

  async function getPlans () {
    const { data }: any = await api.postMethod('subscription_plans', {})

    if (!data.success) {
      history.push('/logout')
      return
    }

    setPlans(data.data)
  }

  async function handleToStripe (subscriptionId: string) {
    const userId = localStorage.getItem('userId')
    const { data: subscription }: any = await api.postMethod('get-payment', { userId })
    if (!subscription.data) {
      const { data: info }: any = await axios.get('https://geolocation-db.com/json/')

      const successUrl = `${window.location.origin}/success-payment?userId=${userId}&subscription_id=${subscriptionId}&payment_mode=subscription`
      const cancelUrl = `${window.location.origin}/plans`

      const { data }: any = await api.postMethod('create-checkout-session', {
        subscription_id: subscriptionId,
        successUrl,
        cancelUrl,
        email: user?.email,
        ip: info.IPv4
      })

      window.location.href = data.url
    } else {
      toast.error('This account already has an active plan.')
    }
  }

  useEffect(() => {
    getPlans()
  }, [])

  if (!plans) {
    return <Loading />
  } */

  return (
    <>
      <Header />
      <ContainerPlan>
        <h1 style={{ textAlign: 'center', marginTop: '200px', marginBottom: '24px' }}>EMC brings you quick wins from real manufacturing Experts!</h1>
        <div style={{ textAlign: 'center', marginBottom: '32px' }}>
          <p>Manufacturers across Canada have questions - EMC&apos;s Manufacturing Masters presents short videos answering those questions! Full access to EMC&apos;s Manufacturing Masters plataform is exclusive to EMC members and their staff. Not yet an EMC member? Please enjoy a free 14-day trial of the platform.</p>
          <div style={{ textAlign: 'center', marginTop: '32px' }}>
            <Link to='/register'>
              <ButtonOrange>
                SIGN UP HERE
              </ButtonOrange>
            </Link>
          </div>
        </div>
        <div style={{ textAlign: 'center' }}>
          <p><a href='https://emccanada.org/become-a-member' target='_blank' rel='noreferrer'><strong style={{ color: 'var(--orange-primary)' }}>Click here</strong></a> to learn more about becoming and EMC member. Our team looking forward to connecting with you.</p>
        </div>
        {/* {plans.filter((plan: any) => plan.price_id.length > 2).map((subscription: any) => (
          <div
            className="col-sm-12 col-md-4 col-lg-4 col-xl-4 mt-5"
            key={subscription.subscription_id}
          >
            <div className="plan-card">
              <div className="plan-head">
                {subscription.title}
              </div>
              <div
                className={
                  'plan-price' +
                  (+subscription.popular_status === 1
                    ? ' premium'
                    : '')
                }
              >
                <p>{t('plan')}</p>
                <h4>
                  {subscription.currency}
                  {subscription.amount} / {t('month')}
                </h4>
              </div>
              <div className="plan-details">
                <h4>{t('maintain_account')}</h4>
                <h5>
                  <i className="fas fa-user-plus" />
                  {subscription.no_of_account}
                </h5>
                <p>{subscription.description}</p>
                <div className="d-flex justify-content-end mt-4">
                  <ButtonOrange onClick={() => handleToStripe(subscription.subscription_id)} className="btn">
                    {t('pay_now')}
                  </ButtonOrange>
                </div>
              </div>
            </div>
          </div>
        ))} */}
      </ContainerPlan>
    </>
  )
}

export default Plan
