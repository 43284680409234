import React from 'react'
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch
} from 'react-router-dom'

import { VideoProvider } from '../contexts/video'

import AddTeamMember from '../pages/Auth/AddTeamMember'
import Home from '../pages/Auth/Home'
import Category from '../pages/Auth/Category'
import SubCategory from '../pages/Auth/SubCategory'
import Experts from '../pages/Auth/Experts'
import ExpertDetails from '../pages/Auth/Experts/ExpertDetails'
import Video from '../pages/Auth/Video'
import ManageTeam from '../pages/Auth/ManageTeam'
import Account from '../pages/Auth/Account'
import EditAccount from '../pages/Auth/Account/EditAccount'
import ChangePassword from '../pages/Auth/Account/ChangePassword'
import DeleteAccount from '../pages/Auth/Account/DeleteAccount'
import Plan from '../pages/Auth/Plan'
import BillingDetails from '../pages/Auth/Plan/BillingDetails'
import SuccessContract from '../pages/Auth/Plan/SuccessContract'
import Logout from '../pages/Auth/Logout'
import ViewAll from '../pages/Auth/ViewAll'
import Trailer from '../pages/Auth/Trailer'
import VideoShare from '../pages/Common/Video'
import ShareVideo from '../pages/Auth/ShareVideo'
import PageCustom from '../pages/Common/PageCustom'
import Register from '../pages/Common/Register'

const Routes = () => (
  <VideoProvider>
    <Router>
      <Switch>
        <Route path="/add-team-member" component={AddTeamMember} />
        <Route path="/home" component={Home} />
        <Route path="/category/:id" component={Category} />
        <Route path="/sub-category/:id/:name" component={SubCategory} />
        <Route path="/experts" exact component={Experts} />
        <Route path="/experts/:id" component={ExpertDetails} />
        <Route path="/video/:id" component={Video} />
        <Route path="/trailer" component={Trailer} />
        <Route path="/view-all/:name" component={ViewAll} />
        <Route path="/manage-team" component={ManageTeam} />
        <Route path="/account" exact component={Account} />
        <Route path="/account/edit" component={EditAccount} />
        <Route path="/account/change-password" component={ChangePassword} />
        <Route path="/account/delete" component={DeleteAccount} />
        <Route path="/plans" exact component={Plan} />
        <Route path="/plans/me" component={BillingDetails} />
        <Route path="/success-payment" component={SuccessContract} />
        <Route path="/logout" component={Logout} />
        <Route path="/change-password" component={ChangePassword} />
        <Route path="/watch/:unique_id/:token" component={VideoShare} />
        <Route path="/share/emails" component={ShareVideo} />
        <Route path="/page/:id" component={PageCustom} />
        <Route path="/register" component={Register} />
        <Route path="**" component={() => <Redirect to="/home" />} />
      </Switch>
    </Router>
  </VideoProvider>
)

export default Routes
