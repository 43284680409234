import React from 'react'
import { t } from 'react-multi-lang'
import { Link } from 'react-router-dom'

import Header from '../../../components/Auth/Header'
import useAuth from '../../../hooks/useAuth'

import {
  ContainerAccount
} from './styled'

const Account = () => {
  const { userDetails } = useAuth()
  return (
    <>
      <Header />
      <ContainerAccount>
        <div className="row">
          <div className="col-sm-12 col-md-12 col-lg-10 col-xl-9 mx-auto mt-5">

            <div className="account-title-sec">
              <h1 className="">{t('account')}</h1>
            </div>

            <div className="account-sec">
              <div className="row">
                <div className="col-12 col-sm-3 col-md-3 col-lg-3 col-xl-3">
                  <h4 className="account-sec-head">
                    {t('profile')}
                  </h4>
                </div>
                <div className="col-12 col-sm-5 col-md-5 col-lg-5 col-xl-5">
                  <h5 className="email">
                    {userDetails?.email}
                  </h5>
                  <h5 className="password">
                    {t('password')}
                    <span className="asterisk">
                      <i className="fas fa-asterisk" />
                      <i className="fas fa-asterisk" />
                      <i className="fas fa-asterisk" />
                      <i className="fas fa-asterisk" />
                      <i className="fas fa-asterisk" />
                      <i className="fas fa-asterisk" />
                    </span>
                  </h5>
                </div>
                <div className="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4">
                  <ul className="account-nav-link">
                    <li>
                      <Link to="/account/edit">
                        {t('edit')}{' '}
                        {t('profile')}
                      </Link>
                    </li>
                    <li>
                      <Link to="/account/change-password">
                        {t(
                          'change_password'
                        )}
                      </Link>
                    </li>
                    <li>
                      <Link to="/account/delete">
                        {t(
                          'delete_account'
                        )}
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            {/* <div className="account-sec">
              <div className="row">
                <div className="col-12 col-sm-3 col-md-3 col-lg-3 col-xl-3">
                  <h4 className="account-sec-head">
                    {t('plan_details')}
                  </h4>
                </div>
                <div className="col-12 col-sm-5 col-md-5 col-lg-5 col-xl-5">
                  <h5 className="email capitalize">
                    {userDetails?.subscription_title}
                  </h5>
                </div>
                <div className="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4">
                  <ul className="account-nav-link">
                    <li>
                      <Link to="/plans">
                        {t('change_plan')}
                      </Link>
                    </li>
                    <li>
                      <Link to="/plans/me">
                        {t(
                          'billing_details'
                        )}
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div> */}

          </div>
        </div>
      </ContainerAccount>
    </>
  )
}

export default Account
