/* eslint-disable camelcase */
import React, { useEffect, useState } from 'react'
import Loading from '../../../components/Loading'
import VideoPlayer from '../../../components/VideoPlayer'
import { Video as IVideo } from '../../../contexts/video'
import api from '../../../services/api'

import { ContainerVideo } from './styled'
import Banner from '../../../components/Auth/Banner'
import { toast } from 'react-toastify'
import { Link } from 'react-router-dom'
import { ButtonOrange } from '../../../components/Buttons'

const Video = (props: any) => {
  const [video, setVideo] = useState<IVideo | null>(null)
  const [success, setSuccess] = useState<boolean>(true)
  const [text, setText] = useState<string | null>(null)

  async function searchVideo (unique_id: string, token: string) {
    const { data }: any = await api.getMethod(`videos/share?video=${unique_id}&token=${token}`)
    setSuccess(data.success)
    if (data.success) {
      setVideo(data.data)
    } else toast.error(data.error_messages)
  }

  async function getSettings () {
    const { data }: any = await api.getMethod('get_text_sharing_video')
    setText(data)
  }

  useEffect(() => {
    searchVideo(props.match.params.unique_id, props.match.params.token)
    getSettings()
  }, [props.match.params.unique_id])

  if (!video) {
    if (!success) return <h1 className="text-center mt-5 container">The link to this video has expired. For unlimited access to all Manufacturing Masters content, <Link to="/register" className="text-orange">sign up here</Link>! </h1>
    else return <Loading />
  }

  return (
    <>
      <Banner />
      <ContainerVideo className="container mt-3">
        {
          video && (
            <>
              <VideoPlayer
                full={false}
                poster={video?.default_image}
                main_resolutions={video?.main_resolutions}
                main_video={video?.main_video}
              />
              <h2 className="text-white mt-5">{text}</h2>
              <div className="my-3 d-flex">
                <ButtonOrange className="mx-auto" href="https://emccanada.org/services/manufacturing-masters">
                  Visit Website
                </ButtonOrange>
              </div>
            </>
          )
        }
      </ContainerVideo>
    </>
  )
}
export default Video
