import React, { useState } from 'react'
import { t } from 'react-multi-lang'
import { Link } from 'react-router-dom'
import { toast } from 'react-toastify'

import iconAdd from '../../../assets/images/add.png'
import iconCheck from '../../../assets/images/tick.png'
import api from '../../../services/api'
import { ButtonOrange, ButtonOutlineWhite } from '../../Buttons'

function VideoOverView ({ videoDetailsFirst }: any) {
  const [wishlistStatusCheck, setWishlistStatusCheck] = useState<number | null>(null)
  // const [like, setLike] = useState<number>(0)
  // const [dislike, setDisLike] = useState<number>(0)
  const dataUserGuest: any = JSON.parse((localStorage?.getItem('user') as string))
  const isGuestUser: string = (localStorage?.getItem('accessToken') as string) + (localStorage?.getItem('userId') as string) + (localStorage?.getItem('name') as string)
  const isGuestUserData: string = dataUserGuest?.token + dataUserGuest?.user_id + dataUserGuest?.name

  async function handleWishList () {
    const { data }: any = await api.postMethod('wishlists/operations', { admin_video_id: videoDetailsFirst.admin_video_id })
    if (data.success) {
      toast.success(data.message)
      setWishlistStatusCheck(data.wishlist_id ? 1 : 0)
    } else {
      toast.success(data.error_messages)
    }
  }

  // async function handleOnClickLike () {
  //   const { data }: any = await api.postMethod('videos/like', { admin_video_id: videoDetailsFirst.admin_video_id })
  //   if (data.success) {
  //     toast.success('You liked this Video!')
  //     setLike(+data.data.like_count)
  //     setDisLike(+data.data.dislike_count)
  //   } else {
  //     toast.error(data.error_messages)
  //   }
  // };

  // async function handleOnClickDislike () {
  //   const { data }: any = await api.postMethod('videos/dis_like', { admin_video_id: videoDetailsFirst.admin_video_id })
  //   if (data.success) {
  //     toast.success('You Disliked this Video!')
  //     setLike(+data.data.like_count)
  //     setDisLike(+data.data.dislike_count)
  //   } else {
  //     toast.error(data.error_messages)
  //   }
  // };

  // useEffect(() => {
  //   setWishlistStatusCheck(+videoDetailsFirst?.wishlist_status)
  //   setLike(videoDetailsFirst?.likes)
  //   setDisLike(+videoDetailsFirst?.dislikes)
  // }, [videoDetailsFirst])

  return (
    <div className="slider-topbottom-spacing">
      <div className="overview-content">

        <h1 className="banner_video_title">{videoDetailsFirst?.title}</h1>
        <h4 className="banner_video_details">
          {/* <span className="green-clr">
            {new Date(videoDetailsFirst?.publish_time).toLocaleDateString('en-US', { year: 'numeric', month: 'short' })}
          </span>
          <span className="grey-box">
            {videoDetailsFirst?.age}
            <i className="fas fa-plus fa-sm" /> /{' '}
            {videoDetailsFirst?.watch_count}{' '}
            <span className="small">Views</span>
          </span> */}
          <span>Video Length: {videoDetailsFirst?.duration}</span>
          {/* <span className="small yellow-clr ml-1">
            <i className="fas fa-star" />
            <i className="fas fa-star" />
            <i className="fas fa-star" />
            <i className="far fa-star" />
            <i className="far fa-star" />
          </span> */}
        </h4>
        <h4 className="banner_video_details">
          {/* <span>
            <i className="far fa-thumbs-up" />
          </span> */}
          {/* <span className="mr-2">
            {like}
          </span>
          <span>
            <i className="far fa-thumbs-down" />
          </span>
          <span className="mr-2">
            {dislike}
          </span> */}
          {videoDetailsFirst?.should_display_ppv === 1
            ? (
              <span className="red-box">
                {videoDetailsFirst?.currency} {videoDetailsFirst?.ppv_amount}
              </span>
              )
            : (
                ''
              )}
        </h4>
        <h4 className="slider_video_text">{videoDetailsFirst?.description}</h4>
        <div className="banner-btn-sec mb-3">
          <Link to={`/video/${videoDetailsFirst?.admin_video_id}`}>
            <ButtonOrange>
              <i className="fas fa-play mr-2" />
              {t('play')}
            </ButtonOrange>
          </Link>

          <ButtonOutlineWhite
            className="mx-2"
            onClick={handleWishList}
          >
            {wishlistStatusCheck
              ? (
                <div>
                  <i className="" style={{ display: 'none' }} />
                  <img
                    src={iconCheck}
                    className="mr-2"
                    alt="icon for visible tick"
                  />
                  {t('my_list')}
                </div>
                )
              : (
                <div>
                  <i className="" style={{ display: 'none' }}></i>
                  <img
                    src={iconAdd}
                    className="mr-2"
                    alt="icon for visible add"
                  />
                  {t('my_list')}
                </div>
                )}
          </ButtonOutlineWhite>

          {/* <div
            onClick={handleOnClickLike}
            className="btn express-btn mr-2"
          >
            <i className="far fa-thumbs-up fa-lg" />
          </div>
          <div
            onClick={handleOnClickDislike}
            className="btn express-btn btn-right-space"
          >
            <i className="far fa-thumbs-down fa-lg" />
          </div> */}
        </div>
        {videoDetailsFirst?.cast_crews.map((cast: any) => (
          <Link className="mt-3" key={cast.cast_crews_id} to={`/experts/${cast.cast_crew_id}`}>
            <ButtonOrange>
              {`${t('contact_expert')} ${cast.name}`}
            </ButtonOrange>
          </Link>
        ))}
        <br /><br />
        {
          !localStorage.getItem('isGuestUser') && !(localStorage.getItem('isGuestUser') === isGuestUser) && !(localStorage.getItem('isGuestUser') === isGuestUserData) && (
            <Link to={{
              pathname: '/share/emails',
              state: { videoDetailsFirst }
            }}>
              <ButtonOrange as="button">
                Share Video
              </ButtonOrange>
            </Link>
          )
        }
        <br /><br />
        {
          videoDetailsFirst?.playbook_url && !localStorage.getItem('isGuestUser') && !(localStorage.getItem('isGuestUser') === isGuestUser) && !(localStorage.getItem('isGuestUser') === isGuestUserData) && (
            <ButtonOrange
              href={videoDetailsFirst?.playbook_url}
              className="mt-5"
              target="_blank"
              download
              rel="noopener noreferrer"
            >
              {`${t('download_the_playbook')}`}
            </ButtonOrange>
          )
        }

      </div>
    </div>
  )
}

export default VideoOverView
