import styled from 'styled-components'

export const ButtonOrange = styled.a`
  background-color: #FFF;
  border: 4px solid var(--orange-primary);
  border-radius: 4px;
  color: var(--orange-primary) !important;
  font-weight: 600;
  padding: .375rem .75rem;
  font-size: 1rem;
  line-height: 1.5;
  text-transform: capitalize;

  :hover {
    background-color: var(--orange-primary);
    color: #fff !important;
  }
`

export const ButtonOutlineWhite = styled.button`
  background-color: transparent;
  border: 2px solid #FFF;
  border-radius: 4px;
  color: #FFF !important;
  font-weight: 500;
  padding: .375rem .75rem;
  font-size: 1rem;
  line-height: 1.5;
  text-transform: capitalize;
`
