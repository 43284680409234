import styled from 'styled-components'

export const ContainerHeader = styled.div`
  background-repeat: no-repeat;
  width: 100%;
  background-position: center;
  background-size: cover;
  background-blend-mode: overlay;
  background-color: rgba(2, 18, 34, 0.55);
  min-height: 60vh;
  border-bottom: 8px solid var(--blue-primary);
  display: flex;
  flex-direction: column;
  color: white;  
  position: relative;
`

export const Video = styled.video`
  right: 7em;
  position: absolute;
  width: 55%;
  height: 60vh;
  padding-bottom: 8px;
  @media (max-width: 768px) {
    width: 100%;
    right: 0;
  }
`

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 2em 4em;
  width: 100%;
  z-index: 10;

  .new-logo {
    height: 6em;
    width: auto;
  }

  .new-logo-word {
    height: 4em;
    width: auto;
    margin-left: 1em;
  }

  .signin-btn {
    background-color: white;
    border: 4px solid var(--orange-primary);
    border-radius: 4px;
    color: var(--navy);
    line-height: normal;
    padding: 0.5em 1em;
    font-weight: 700;
    font-size: 1.5rem;

    :hover {
      background-color: var(--orange-primary);
      color: #fff;
    }
  }
`

export const Banner = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  z-index: 10;
  width: 20%;
  text-align: left;
  max-width: 960px;
  padding-left: 20rem;
  
  h1 {
    font-size: 2.8em;
    line-height: 1.1;
    font-weight: bold;
    margin-bottom: 20px;
    font-family: inherit;
  }
`

export const GetStarted = styled.div`
  width: 100%;
  position: absolute;
  bottom: 0;
  display: flex;
  justify-content: center;

  a {
    font-size: 1.625rem;
    min-height: 60px;
    background-color: white;
    border: 4px solid var(--orange-primary);
    border-radius: 4px;
    color: var(--navy);
    -webkit-box-shadow: 0 1px 0 rgba(0, 0, 0, .45);
    -moz-box-shadow: 0 1px 0 rgba(0, 0, 0, .45);
    box-shadow: 0 1px 0 rgba(0, 0, 0, .45);
    padding: 0 1em;
    font-weight: 600;
    padding: 0.5em 1em;
    position: absolute;
    top: -30px;

    :hover {
      background-color: var(--orange-primary);
      color: #fff;
    }
  }
`

export const VideoSection = styled.div`
  border-bottom: 8px solid var(--blue-primary);
  padding: 60px 0;
  font-size: 3rem;
  line-height: 1.1;
  font-weight: 700;
  margin: 0 0 .4em;
  color: #FFF;

  h2 {
    font-size: 3rem;
    line-height: 1.1;
    font-weight: 700;
    margin: 0 0 .4em;
  }

  h4 { 
    font-size: 1.625rem;
    font-weight: 500;
    line-height: 1.4;
    margin: .75em 0 .25em;
  }
`

export const SmallVideo = styled.video`
  width: 100%;
  border: 1px solid var(--orange-primary);
`

export const FaqContainer = styled.div`
  padding: 70px 45px;
  position: relative;
  border-bottom: 8px solid var(--blue-primary);
  padding: 50px 5%;
  margin-bottom: 0;
  color: white;

  img { 
    position: absolute;
    height: 6em;
    width: auto;
  }

  h2 {
    font-size: 2.5rem;
    line-height: 1.1;
    font-weight: 700;
    color: white;
    text-align: center;
  }

  h4 { 
    font-size: 1.75rem;
    line-height: 1.1;
    font-weight: 400;
    color: white;
    text-align: center;
    margin-top: 1.25em;
  }

  a {
    font-size: 1.625rem;
    min-height: 60px;
    background-color: white;
    border: 4px solid var(--orange-primary);
    border-radius: 4px;
    color: var(--navy);
    -webkit-box-shadow: 0 1px 0 rgba(0, 0, 0, .45);
    -moz-box-shadow: 0 1px 0 rgba(0, 0, 0, .45);
    box-shadow: 0 1px 0 rgba(0, 0, 0, .45);
    padding: 0 1em;
    font-weight: 600;
    padding: 0.5em 1em;
    text-align: center;

    :hover {
      background-color: var(--orange-primary);
      color: #fff;
    }
  }

`

export const FaqList = styled.div`
  font-size: 1.5rem;
  display: grid;
  gap: 24px;
  grid-template-columns: repeat(5, 1fr);
  width: 100%;
  align-items: stretch;
  margin-top: 2em;
  margin-bottom: 3em;
  color: black;

  .card {
    cursor: pointer;
    flex-shrink: 0;
    font-size: 1.2em;
    font-weight: 500;
  }

  @media (max-width: 768px) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media (max-width: 425px) {
    grid-template-columns: repeat(1, 1fr);
  }
`
