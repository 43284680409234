import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

import api from '../../services/api'

import { menus } from '../Footer'
import { FooterList } from '../Footer/styled'

const StaticSidebar = () => {
  const [menus, setMenus] = useState<menus[]>([])

  async function getMenus () {
    const { data } = await api.getMethod('pages/list')
    setMenus(data.data)
  }

  useEffect(() => {
    getMenus()
  }, [])

  return (
    <div className="container">
      <FooterList>
        {
          menus.map((staticPage: menus, index: number) => (
            <li key={`page-${index}`}>
              <Link to={`/page/${staticPage.page_type}`}>
                {staticPage.title}
              </Link>
            </li>
          ))
        }
      </FooterList>
    </div>
  )
}

export default StaticSidebar
