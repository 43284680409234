/* eslint-disable */
import React, { useEffect, useRef, useState } from 'react'
import { useHistory } from 'react-router-dom'
import Loading from '../../../components/Loading'
import VideoPlayer from '../../../components/VideoPlayer'
import { Video as IVideo } from '../../../contexts/video'
import useVideo from '../../../hooks/useVideo'
import api from '../../../services/api'
import arrow from '../../../assets/images/left-arrow.png'

import { BackPage, ContainerVideo } from './styled'
import { toast } from 'react-toastify'

const Video = (props: any) => {
  const { setCurrentVideo } = useVideo()
  const [video, setVideo] = useState<IVideo | null>(null)
  const history = useHistory()
  const spentTimeWatchingVideo = useRef<number>(0)

  const btnBack = () => window.history.back()

  async function searchVideo (admin_video_id: string) {
    const { data }: any = await api.postMethod('videos/view', {
      admin_video_id,
      skip: 0
    })

    if (data.data.is_user_need_subscription) {
      toast.error('Manufacturing Masters is brought to you by EMC. For full access, become an EMC member or sign-up for a free trial.')
      history.push('/plans')
    } else {
      setVideo(data.data)
      setCurrentVideo(data.data)
    }
  }

  function handleTimer (player: any) {
    const interval = 5000
    const timer = setInterval(() => {
      if (!player.current) {
        clearInterval(timer)
        return
      }
      if (player.current.paused()) {
        clearInterval(timer)
        return
      }
      spentTimeWatchingVideo.current += interval
    }, interval)
    player.current.on('ended', () => {
      clearInterval(timer)
    })
    return () => clearInterval(timer)
  }

  useEffect(() => {
    const onUnmountPageHandler = async () => {
      await api.postMethod('watch-user-video', {
        user: localStorage.getItem('userId'),
        video: props.match.params.id,
        timeSpent: spentTimeWatchingVideo.current / 60000
      })
    }
    const beforeUnloadHandler = (event: BeforeUnloadEvent) => {
      window.removeEventListener('beforeunload', beforeUnloadHandler)
      return onUnmountPageHandler()
    }
    window.addEventListener('beforeunload', beforeUnloadHandler)
    return () => {
      onUnmountPageHandler()
      window.removeEventListener('beforeunload', beforeUnloadHandler)
    }
  }, [])

  useEffect(() => {
    searchVideo(props.match.params.id)
  }, [props.match.params.id])

  if (!video) {
    return <Loading />
  }

  return (
    <ContainerVideo>
      <VideoPlayer
        poster={video?.default_image}
        main_resolutions={video?.main_resolutions}
        main_video={video?.main_video}
        full={true}
        handleTimer={handleTimer}
      />
      <div onClick={btnBack} style={{ cursor: 'pointer' }}>
        <BackPage>
          <img
            src={arrow}
            alt="arrow"
          />
          {video.title}
        </BackPage>
      </div>
    </ContainerVideo>
  )
}
export default Video
