import styled from 'styled-components'

export const ContainerEditAccount = styled.div`
  margin-top: 6em;

  .register-box-head { 
    font-size: 2em;
    margin-bottom: .714em;
    color: #FFF;
  }

  .auth-form {
    label{
      color: #FFF;
      margin-bottom: 0;
      text-transform: capitalize;
    }

    .form-control{
      padding: .65rem 1rem;
      border-radius: 4px;
      font-size: 1.14em;
      margin-top: .25em;
      appearance: none;
      box-shadow: none;
      color: #333;
      height: 48px;
      :focus{
        box-shadow: none;
      }
    }
  }
`
