import styled from 'styled-components'

export const CategoryBox = styled.img`
  margin: 2vw;
  border-radius: 16px;
  width: 15vw;
  height: 12vh;
  object-fit: cover;
  display: flex;
  align-items: center;
  justify-content: center;

  @media screen and (max-width: 1460px){
    height: 11vh;
    width: 14vw;
  }

  @media screen and (max-width: 992px){
    flex-direction: column;
    width: 120px;
    height: 100px;
  }
`

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #FFF; 
  height: 15vh;
  width: 100%;

  @media (max-width: 425px) {
    position: fixed;
    z-index: 9999;
    height: 17vh;
    top: 57px
  }

  @media screen and (max-width: 992px){
    overflow-x: scroll;
    -ms-overflow-style: none;

    ::-webkit-scrollbar {
    display: none;
    }
  }
`

/* .category-box {
  margin: 16px;
  border-radius: 16px;
  width: 280px;
  height: 125px;
  object-fit: cover;
} */
