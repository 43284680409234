/* eslint-disable camelcase */
import React, { useEffect, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'

import Header from '../../../components/Auth/Header'
import { ButtonOrange } from '../../../components/Buttons'
import Loading from '../../../components/Loading'
import api from '../../../services/api'

import {
  ContainerCastCrew,
  ContainerExpert,
  Info,
  Picture
} from './styled'

export type CastCrews = {
  category: string,
  experts: Expert[]
}

export type Expert = {
  category_id: number
  created_at: Date
  description: string
  email: string
  id: number
  image: string
  name: string
  status: number
  sub_categories: string
  sub_category_id: number
  unique_id: string
  updated_at: Date
}

const Home = () => {
  const [castCrews, setCastCrews] = useState<CastCrews[]>([])
  const history = useHistory()

  async function getExperts () {
    const { data }: any = await api.postMethod('v4/categoriesCastCrews', {})
    if (!data.success) {
      history.push('/logout')
      return
    }
    setCastCrews(data.cast_crews)
  }

  useEffect(() => {
    getExperts()
  }, [])

  if (castCrews.length === 0) {
    return <Loading />
  }

  return (
    <>
      <Header />
      <ContainerCastCrew className="container-fluid">
        {
          castCrews.map(({ category, experts }: CastCrews, key: number) => (
            <div key={key} className="mt-5" >
              <h2>{category}</h2>
              <ContainerExpert className="mt-3">
                {
                  experts.map((expert: Expert, key: number) => (
                    <div key={key} className="d-flex align-items-center mb-3 ml-2">
                      <Picture src={expert.image} alt={expert.name} />
                      <Info className="d-flex align-items-start justify-content-between w-100 flex-column">
                        <p className="m-0">{expert.name}</p>
                        <strong className="py-3">{expert.sub_categories}</strong>
                        <Link to={`experts/${expert.id}`}>
                          <ButtonOrange className="btn">
                            See videos
                          </ButtonOrange>
                        </Link>
                      </Info>
                    </div>
                  ))
                }
              </ContainerExpert>
            </div>
          ))
        }
      </ContainerCastCrew>
    </>
  )
}

export default Home
