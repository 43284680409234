/* eslint-disable camelcase */
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { t as translate } from 'react-multi-lang'

import logoPlayStore from '../../assets/images/play-store.png'
import logoAppStore from '../../assets/images/app-store.png'

import api from '../../services/api'
import {
  ContainerFooter,
  FooterList,
  ImageDownloaded
} from './styled'

export interface menus {
  description: string
  page_id: number
  page_type: string
  status: number
  title: string
  unique_id: string
  updated_at: Date
  created_at: Date

}

const Footer = () => {
  const [menus, setMenus] = useState<menus[]>([])

  async function getMenus () {
    const { data } = await api.getMethod('pages/list')
    setMenus(data.data)
  }

  useEffect(() => {
    getMenus()
  }, [])

  return (
    <ContainerFooter>
      <footer>
        <p className="footer-top">
          <a rel="noopener noreferrer" href="https://docs.google.com/forms/d/e/1FAIpQLSd2PNeRzJq0NgEEFuUySaWN2Mz-FEiL-qAPeuu_MGb394mWsw/viewform?usp=sf_link" target="_blank">{translate('questions_contact_us')}</a>
        </p>
        <div className="row">
          <div className="col-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
            <FooterList>
              {
                menus.map((staticPage: menus, index: number) => (
                  <li key={`page-${index}`}>
                    <Link to={`/page/${staticPage.page_type}`}>
                      {staticPage.title}
                    </Link>
                  </li>
                ))
              }
            </FooterList>
          </div>
          <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
            <div className="row">
              <div className="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                <p className="footer-head">
                  {translate('get_app')}
                </p>
                <a
                  href="#"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <ImageDownloaded
                    src={logoAppStore}
                    className="app-img"
                    alt="App Store Image"
                  />
                </a>
                <a
                  href="#"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <ImageDownloaded
                    src={logoPlayStore}
                    className="app-img"
                    alt="Play Store Image"
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
        <p className="footer-bottom">
          Manufacturing Masters
        </p>
      </footer>
    </ContainerFooter>
  )
}

export default Footer
