/* eslint-disable camelcase */
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import { toast } from 'react-toastify'

// Components
import UserHeader from '../../../components/Auth/Header'
import { ButtonOrange } from '../../../components/Buttons'
import Loading from '../../../components/Loading'
import useAuth from '../../../hooks/useAuth'
import api from '../../../services/api'
import { SmallVideo } from '../../Common/Landpage/styled'

const ShareEmails = (props: any) => {
  const [email, setEmail] = useState('')
  const [content, setContent] = useState('')
  const [emails, setEmails] = useState<string[]>([])
  const [video, setVideo] = useState<any>()

  const history = useHistory()
  const { user } = useAuth()

  async function searchVideo (admin_video_id: string) {
    const { data }: any = await api.postMethod('videos/view', {
      admin_video_id,
      skip: 0
    })

    if (data.data.is_user_need_subscription) {
      toast.error('To share this video you need to hire a plan. Contact your boss or sign-up.')
      history.push('/plans')
    } else {
      console.log(data.data)
      setVideo(data.data)
    }
  }

  useEffect(() => {
    searchVideo(props.location.state?.videoDetailsFirst.admin_video_id as string)
  }, [])

  async function share () {
    if (emails.length > 0) {
      const { data }: any = await api.postMethod('videos/share', {
        emails,
        share_link: video.share_link,
        sub_category_id: video.sub_category_id,
        name: user?.name,
        content
      })

      if (data.success) {
        toast.success('Video shared')
        history.push('/')
      }
    } else toast.error('Please, enter with email or click in add new email')
  }

  if (!video) {
    return <Loading />
  }

  return (
    <>
      <UserHeader />
      <br /><br /><br />
      <div className="container mt-5">
        <h2 className="text-center">To share a video, enter emails below.</h2>
        <div className="row mt-5">
          <div className="col-md-5">
            <div className="mb-3">
              <textarea
                className="mb-3 form-control"
                placeholder="Add a personalized message"
                value={content}
                onChange={text => setContent(text.target.value)}
                rows={3}
              />
              <div className="input-group mb-3">
                <input type="email"
                  onChange={text => setEmail(text.target.value)}
                  className="form-control"
                  id="email"
                  name="email"
                  placeholder="Enter e-mail"
                  value={email}
                />
                <div
                  onClick={() => { setEmails([...emails, email]); setEmail('') }}
                  className ="input-group-append"
                  style={{ cursor: 'pointer' }}
                >
                  <span className ="input-group-text" id="basic-addon2">Add</span>
                </div>
              </div>
            </div>
            {
              emails.map((emailItem: any, index: number) => (
                <div key={index} className="mb-2 d-flex justify-content-between">
                  <p>{emailItem}</p>
                  <button className="btn btn-danger btn-sm" onClick={() => { setEmails(emails.filter(email => email !== emailItem)) }}>
                    <i className="fa fa-trash" />
                  </button>
                </div>
              ))
            }
            {emails.length > 0 && (
              <ButtonOrange as="button" onClick={share}>
                Share
              </ButtonOrange>
            )}
          </div>
          <div className="col-md-1" />
          {
            video?.main_video &&
            (
              <div className="col-md-6">
                <h4 className="mb-3">Preview of the video to be shared</h4>
                <SmallVideo
                  src={video.main_video}
                  controls
                />
              </div>
            )
          }
        </div>
      </div>
    </>
  )
}

export default ShareEmails
