import React from 'react'
import { Link } from 'react-router-dom'

import { t } from 'react-multi-lang'
import Helper from '../../../helper/helper'
import Logo from '../../Logo'
import menuIcon from '../../../assets/images/menu.png'
import { ButtonOutlineWhite } from '../../Buttons'

class UserHeader extends Helper {
    state = {
      isGuestUser: true,
      isSuperUser: null,
      categories: null,
      mobileSidebar: false
    };

    toggleMobileSidebar = () => this.setState({ mobileSidebar: !this.state.mobileSidebar })

    componentDidMount () {
      this.state.isSuperUser = Boolean(+localStorage.getItem('isSuperUser'))
      const dataUserGuest = JSON.parse(localStorage.getItem('user'))
      const isGuestUser = localStorage.getItem('accessToken') + localStorage.getItem('userId') + localStorage.getItem('name')
      const isGuestUserData = dataUserGuest.token + dataUserGuest.user_id + dataUserGuest.name
      if (localStorage.getItem('isGuestUser') && localStorage.getItem('isGuestUser') === isGuestUser) {
        this.state.isGuestUser = false
      }
      if (localStorage.getItem('isGuestUser') === isGuestUserData) {
        this.state.isGuestUser = false
      }
    }

    render () {
      const {
        isSuperUser,
        isGuestUser
      } = this.state

      return (
        <div>
          <nav
            className="navbar navbar-expand main-nav fixed-top header-height-custom"
            id="header"
          >
            <span
              className="menu-icon"
              id="menu_icon"
              onClick={() => this.toggleMobileSidebar()}
            >
              <img
                src={menuIcon}
                alt="menu_img"
              />
            </span>
            <Link className="navbar-brand abs" to="/home">
              <Logo />
            </Link>
            <ul className="navbar-nav desktop-nav ">
              <li className="nav-item active" key="home">
                <Link className="nav-link" to={'/home'}>
                  {t('home')}
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  className="nav-link text-orange"
                  to={'/experts'}
                >
                  {t('experts')}
                </Link>
              </li>
            </ul>
            <ul className="navbar-nav ml-auto">
              {/* <Search /> */}
                <a target="_blank" href="https://www.manufacturing-masters.com/help-emcmembers" rel="noreferrer">
                  <ButtonOutlineWhite
                    rel="noopener noreferrer"
                    className="btn"
                  >
                    Help
                  </ButtonOutlineWhite>
                </a>
                <li className="nav-item dropdown mobile-view">
                  <Link
                    className="nav-link dropdown-toggle"
                    to="#"
                    data-toggle="dropdown"
                  >
                    <img
                      src={localStorage.getItem(
                        'active_profile_image'
                      )}
                      className="nav-profile-img"
                      alt="profile_img"
                    />
                  </Link>
                  <div className="dropdown-menu profile-drop">
                    {
                      isSuperUser && isGuestUser && (
                        <Link
                        className="dropdown-item"
                        to="/manage-team"
                        >
                          {t('manage_users')}
                        </Link>
                      )
                    }
                    {
                      isGuestUser && (
                        <Link className="dropdown-item" to="/account">
                          {t('account')}
                        </Link>
                      )
                    }
                    <Link className="dropdown-item" to={'/logout'}>
                      {t('signout')}
                    </Link>
                  </div>
                </li>
            </ul>
        </nav>
        <div className="header-height" />

        <div
          className="mobile-sidebar"
          id="menu_content"
          style={{ display: this.state.mobileSidebar ? 'block' : 'none' }}
        >
          <div className="sidebar-content">
            <ul className="sidebar-menu pt-5" id="mobile-side-menu">

              <li key="home-sidemenu">
                <Link to="/home">{t('home')}</Link>
              </li>

              <li className="nav-item">
                <Link
                  className="nav-link text-orange"
                  to={'/experts'}
                >
                  {t('experts')}
                </Link>
              </li>
              <div className="mt-5">
                {
                  isSuperUser && isGuestUser && (
                    <li key="manage-team-sidemenu">
                      <Link to="/manage-team">{'manage_users'}</Link>
                    </li>
                  )
                }
                {
                  isGuestUser && (
                    <li key="account-sidemenu">
                      <Link to="/account">{'account'}</Link>
                    </li>
                  )
                }

                <li key="logout-sidemenu">
                  <Link to="/logout">{t('logout')}</Link>
                </li>
              </div>
            </ul>
          </div>
        </div>
      </div>)
    }
}
export default UserHeader
