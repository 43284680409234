import React from 'react'
import { Link } from 'react-router-dom'
import cx from 'classnames'
import SliderContext from './context'
import ShowDetailsButton from './ShowDetailsButton'
import Mark from './Mark'
import { Image } from './ItemStyle'
import Helper from '../../../../helper/helper'

class Item extends Helper {
    state = {
      loadingFirst: true,
      videoDetailsFirst: null,
      redirect: false,
      redirectPPV: false,
      redirectPaymentOption: false,
      playButtonClicked: false
    };

    componentDidMount () {
      this.setState({ playButtonClicked: false })
    }

    handlePlayVideo = async event => {
      event.preventDefault()

      const inputData = {
        admin_video_id: this.props.movie.admin_video_id,
        skip: 0
      }

      await this.onlySingleVideoFirst(inputData)

      this.redirectStatus(this.state.videoDetailsFirst)
      this.setState({ playButtonClicked: true })
    }

    render () {
      const { movie, index } = this.props

      if (this.state.playButtonClicked) {
        const returnToVideo = this.renderRedirectPage(
          this.state.videoDetailsFirst
        )

        if (returnToVideo !== null) {
          return returnToVideo
        }
      }

      return (
            <SliderContext.Consumer>
                {({ onSelectSlide, currentSlide, elementRef }) => {
                  const isActive = currentSlide && currentSlide.admin_video_id === movie.admin_video_id

                  return (
                        <Image
                            ref={elementRef}
                            className={cx(`item sliderthumb-text item-${index}`, {
                              'item--open': isActive
                            })}
                            // imageMobile={movie.mobile_image}
                        >
                          <div className='boxart'>
                            <img src={movie.default_image} />
                            <div onClick={() => onSelectSlide(movie)} className="width-100 slider-content-box text-white">
                                <div className="d-column justify-content-around">
                                  {/* <div className='d-flex width-80 height-90 justify-content-between'> */}
                                  <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                                    <div className='item-top'>
                                      <Link to={`/video/${movie.admin_video_id}`}>
                                          <div className="thumb-playicon" style={{ height: '3vmax', width: '3vmax' }}>
                                            <i className="fas fa-play text-orange" style={{ display: 'flex', justifyContent: 'center', alignItens: 'center' }}/>
                                          </div>
                                      </Link>
                                      <Link to={{ pathname: '/share/emails', state: { videoDetailsFirst: movie } }}>
                                          <div className="thumb-playicon" style={{ height: '3vmax', width: '3vmax' }}>
                                            <i className="fas fa-share text-orange" style={{ display: 'flex', justifyContent: 'center', alignItens: 'center' }}/>
                                          </div>
                                      </Link>
                                    </div>
                                  </div>
                                  <div className='d-column justify-content-around'>
                                    <h4 className="thumb-title">{movie.title}</h4>
                                    <h5 className="thumb-details"></h5>
                                  </div>
                                </div>
                                <p className="thumb-desc height-100">
                                    {movie.description}{' '}
                                </p>
                                <ShowDetailsButton
                                    onClick={() => onSelectSlide(movie)}
                                />
                            </div>
                            {isActive && <Mark />}
                          </div>
                        </Image>
                  )
                }}
            </SliderContext.Consumer>
      )
    }
}

export default Item
