import styled from 'styled-components'
export const Image = styled.div`
  transition: transform 300ms ease 50ms;
  border-radius: 8px;
  box-sizing: border-box;
  z-index: 1;
  width:33.3333333%;
  display: inline-block;
  white-space: normal;
  margin: 0 0.3vw;
  vertical-align: top;
  position:relative;

  img {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    border-radius:0.3rem;
    height:auto;
  }
  .boxart{
    padding: 28.125% 0;
    overflow:hidden;
  }
  .item-top{
    display:flex;
    justify-content: space-between;
    width:100%;
    align-items: center;
  }
  .slider-content-box {
    font-size: 0.7em;
    padding: 1em;
    position: absolute;
    top: 0;
    bottom: 0;
    background-image: linear-gradient(to top, #000000, rgba(0, 0, 0, 0.9), rgba(0, 0, 0, 0.5), transparent);
    display: none;
    height: 14.1vw;
    border-radius: 0.3rem;
    // z-index: 3;
  }
  &:hover {
    .slider-content-box {
      display: block;
    }
  }
  .thumbarrow-sec {
    .thumbarrow-white {
      display: none;
    }
    .thumbarrow-red {
      display: block;
    }
  }
  .show-details-button {
    span {
      display: none !important;
    }
  }
  .slider--open {
    .item {
      &:hover {
        .slider-content-box {
          display: none;
        }
      }
    }
  }
  @media(max-width: 500px){
    width: 45.8888%;
  }

  @media(min-width: 800px) and (max-width: 1100px){
    width: 25%;
  }

  @media(min-width: 1101px){
    width: 20%;
  }

  @media(max-width: 550px){
  }
  `
