/* eslint-disable camelcase */
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

import arrowRight from '../../assets/images/chevron-right.svg'
import arrowLeft from '../../assets/images/chevron-left.svg'

import api from '../../services/api'

import { CategoryBox, Container } from './style.js'

type category = {
  category_id: number,
  is_approved: number,
  is_series: number,
  name: string
  picture: string
  status: string
}

function MenuCategories () {
  const [categories, setCategories] = useState<category[]>([])
  const [sliceArray, setSliceArray] = useState([0, document.documentElement.clientWidth <= 425 ? 2 : document.documentElement.clientWidth <= 991 ? 4 : 5])

  async function getCategories () {
    const { data }: any = await api.postMethod('v4/categories/list', {})
    if (data.success === true) {
      setCategories(data.data)
    }
  }

  function handleClickNext () {
    if (sliceArray[1] < categories.length) {
      setSliceArray([sliceArray[0] + 1, sliceArray[1] + 1])
    }
  }

  function handleClickPrevious () {
    if (sliceArray[0]) {
      setSliceArray([sliceArray[0] - 1, sliceArray[1] - 1])
    }
  }

  useEffect(() => {
    getCategories()
  }, [])

  if (!categories) {
    return <div />
  }

  return (
    <Container>
      <img style={sliceArray[0] <= 0 ? { opacity: 0.2, cursor: 'no-drop', width: 64 } : { opacity: 1, cursor: 'pointer', width: 64 }} onClick={handleClickPrevious} src={arrowLeft} alt="icon arrow left" />
      {
        categories.slice(sliceArray[0], sliceArray[1]).map((category: category) => (
          <Link key={category.category_id} className="d-flex" to={`/category/${category.category_id}`}>
            <CategoryBox src={category.picture} alt={category.name} />
          </Link>
        ))
      }
      <img style={sliceArray[1] >= categories.length ? { opacity: 0.2, cursor: 'no-drop', width: 64 } : { opacity: 1, cursor: 'pointer', width: 64 }} onClick={handleClickNext} src={arrowRight} alt="icon arrow right" />
    </Container>
  )
}

export default MenuCategories
