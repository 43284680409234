import React, { useEffect, useState } from 'react'
import { t } from 'react-multi-lang'
import { useHistory } from 'react-router'
import { toast } from 'react-toastify'

import Header from '../../../../components/Auth/Header'
import useAuth from '../../../../hooks/useAuth'
import api from '../../../../services/api'
import { Button } from '../../../Common/ForgotPassword/styled'

import {
  ContainerEditAccount
} from './styled'

const EditAccount = () => {
  const [buttonDisable, setButtonDisable] = useState(false)
  const [loadingContent, setLoadingContent] = useState<string | null>(null)
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')

  const history = useHistory()
  const { userDetails, me } = useAuth()

  useEffect(() => {
    setName(userDetails?.name)
    setEmail(userDetails?.email)
  }, [])

  async function handleSubmit (event: any) {
    event.preventDefault()
    setLoadingContent(t('button_loading'))
    setButtonDisable(true)

    const { data }: any = await api.postMethod('updateProfile', { name, email })

    if (data.success) {
      me()
      toast.success(data.message)
      history.push('/account')
    } else toast.error(data.error_messages)

    setLoadingContent(null)
    setButtonDisable(false)
  }

  return (
    <>
      <Header />
      <ContainerEditAccount>
        <div className="row mt-5">
          <div className="col-sm-9 col-md-7 col-lg-5 col-xl-4 mx-auto">
            <h3 className="register-box-head">
              {t('edit')} {t('profile')}
            </h3>
            <form
              onSubmit={handleSubmit}
              className="auth-form"
            >
              <div className="form-group">
                <label htmlFor="name">
                  {t('full_name')}
                </label>
                <input
                  type="text"
                  onChange={text => setName(text.target.value)}
                  className="form-control"
                  id="name"
                  name="name"
                  value={name}
                />
              </div>
              <div className="form-group">
                <label htmlFor="email">
                  {t('email_address')}{' '}
                </label>
                <input
                  type="text"
                  onChange={text => setEmail(text.target.value)}
                  className="form-control"
                  id="email"
                  name="email"
                  value={email}
                />
              </div>
              <Button
                className="btn mt-4 w-100"
                disabled={buttonDisable}
              >
                {
                  loadingContent != null
                    ? loadingContent
                    : t('save')
                }
              </Button>
            </form>
          </div>
        </div>
      </ContainerEditAccount>
    </>
  )
}

export default EditAccount
