import styled from 'styled-components'

export const ContainerLogin = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  min-height: 100vh;

  .bg-img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
  }
`

export const Image = styled.img`
  width: auto;
  height: 7em;
  margin: 1.5em 4%;
`

export const Form = styled.form`
  flex: 1;
  background: #FFF;
  padding: 2em 2em;
  border-radius: 8px;
  display: flex;
  flex-direction: column;

  .forgot-password {
    color: black;
    text-transform: capitalize;
    font-weight: 700;
    font-size: 1.25rem;
  }

  .free-trial-btn {
    background-color: #FFF;
    border: 4px solid var(--orange-primary);
    border-radius: 4px;
    color: var(--navy);
    line-height: normal;
    padding: 0.5em 1em;
    font-weight: 700;
    font-size: 1.5rem;

    :hover {
      background-color: var(--orange-primary);
      color: #fff;
    }
  }

  span {
    margin-top: .714em;
    color: #333;

    a { 
      color: var(--orange-primary) !important;
      text-transform: capitalize;
    }
  }
`

export const Input = styled.input`
  background-color: var(--gray-primary);
  color: #FFF !important;
  font-weight: 400;
  font-size: 1.14em;

  border-radius: 4px;
  padding: .65rem 1rem;
  margin-top: .25em;
  height: 60px;

  appearance: none;
  box-shadow: none;
  transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;

  &::placeholder {
    color: #FFF;
  }

  &:focus {
    background: var(--gray-primary) !important;
    border-color: #80bdff;
    outline: 0;
    box-shadow: none;

    ::-webkit-input-placeholder {
      font-size: .8em;
      position: relative;
      top: -20px; 
    }

    &::-ms-value {
      color: #FFF ;
      background-color: var(--gray-primary);
    }
  }

`

export const Button = styled.button`
  background-color: #FFF;
  border: 4px solid var(--orange-primary);
  border-radius: 4px;
  color: var(--navy);
  line-height: normal;
  padding: 0.5em 1em;
  font-weight: 700;
  font-size: 1.5rem;

  :hover {
    background-color: var(--orange-primary);
    color: #fff;
  }
`

export const CheckboxDiv = styled.div`
  display: flex;
  align-items: center;
  width: 95%;
`

export const CheckboxText = styled.div`
  color: black;
  opacity: none;
  padding-left: 3px;
  display: flex;
  width: 95%;

  :Link {
    color: orange !important;
  }
`

export const OrangeText = styled.div`
  color: #dc6701;
  padding-left: 5px;
`
