import React from 'react'
// import { useHistory } from 'react-router'
// import { toast } from 'react-toastify'

import Header from '../../../../components/Auth/Header'
// import useAuth from '../../../../hooks/useAuth'
// import api from '../../../../services/api'
// import { Button } from '../../../Common/ForgotPassword/styled'

import {
  ContainerDeleteAccount
} from './styled'

const DeleteAccount = () => {
  // const [buttonDisable, setButtonDisable] = useState(false)
  // const [loadingContent, setLoadingContent] = useState<string | null>(null)
  // const [password, setPassword] = useState('')
  // const { logOut } = useAuth()
  // const history = useHistory()

  // async function handleDelete (event: any) {
  //   event.preventDefault()
  //   setLoadingContent(t('button_loading'))
  //   setButtonDisable(true)
  //   const { data }: any = await api.postMethod('deleteAccount', { password })
  //   if (data.success) {
  //     logOut()
  //     toast.success(data.message)
  //     history.push('/')
  //   } else toast.error(data.error_messages)
  // }
  return (
      <>
        <Header />
        <ContainerDeleteAccount>
          <div className="row mt-5">
            <div className="col-sm-9 col-md-7 col-lg-5 col-xl-4 mx-auto">
              <h3 className="register-box-head">
                Delete Account
              </h3>
              <p className="note text-white">
                <strong>Note: </strong>
                If you delete your account you will lose your history and wish-list details.
                <br /><br />
                To delete your account please contact the administrator: vince@mitchellindustries.ca
              </p>
              {/* <form
                onSubmit={handleDelete}
                className="auth-form"
              >
                <div className="form-group">
                  <label htmlFor="password">
                    {t('password')}
                  </label>
                  <input
                    type="password"
                    onChange={text => setPassword(text.target.value)}
                    className="form-control"
                    id="password"
                    name="password"
                    value={password}
                  />
                </div>
                <Button
                  className="btn mt-4 w-100"
                  disabled={buttonDisable}
                >
                  {loadingContent != null
                    ? loadingContent
                    : t('delete')}
                </Button>
              </form> */}
            </div>
          </div>
        </ContainerDeleteAccount>
      </>
  )
}

export default DeleteAccount
