import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'

// Components
import UserHeader from '../../../components/Auth/Header'
import Item from '../../../components/Auth/SliderView/MainSlider/Item'
import Slider from '../../../components/Auth/SliderView/MainSlider/Slider'
import Loading from '../../../components/Loading'

import { Video } from '../../../contexts/video'
import api from '../../../services/api'

const ViewAll = (props: any) => {
  const [movies, setMovies] = useState<any>([])

  const history = useHistory()

  function chunkArray (myArray: any[], size: number) {
    const results = []

    while (myArray.length) {
      results.push(myArray.splice(0, size))
    }

    return results
  }

  function getParameter () {
    switch (props.match.params.name) {
      case 'New Releases':
        return 'URL_TYPE_NEW_RELEASE'
      case 'Recommended':
        return 'URL_TYPE_SUGGESTION'
      case 'My List':
        return 'URL_TYPE_WISHLIST'
    }
  }

  async function searchMovie () {
    const { data }: any = await api.postMethod('see_all', { url_type: getParameter() })

    if (!data.success) {
      history.push('/logout')
      return
    }

    const videos = data.data.map((movie: any) => {
      return movie.in_production && movie
    }).filter((m: any) => m)

    const result = chunkArray(videos, 5)
    console.log(data)
    setMovies(result)
  }

  useEffect(() => {
    searchMovie()
  }, [])

  if (!movies) {
    return <Loading />
  }

  return (
    <>
      <UserHeader />
      <div className="mt-5" />
      <div className="main-slidersec">
        <h3 className="heading-3">{props.match.params.name}</h3>

        {movies.map((res: any) => (
          <Slider key={res.index} className="viewall-slide">
             <div style={{ margin: '0.1vmax 0' }}>
            {res.map((movie: Video, i: number) => (
                <Item movie={movie} key={movie.admin_video_id} index={i}>
                  item1
                </Item>
            ))}
            </div>
          </Slider>
        ))}
      </div>
    </>
  )
}

export default ViewAll
