import React from 'react'
import styled from 'styled-components'
import logoHeader from '../../assets/images/EMC_Combination Logo_White.png'

const Container = styled.div`
  display: flex;
  align-items: center;

  .mark {
    height: 4em;
    width: auto;
    background: transparent;
  }

  .word {
    height: 2em;
    width: auto;
    margin-left: 0.5em;
  }

  @media (max-width: 425px) {
    .mark {
      height: 2.5em;
    }

    .word {
      height: 1.7em;
      margin-left: 0.5em;
    }
  }
`

const Logo = () => {
  return (
    <Container>
      <img
        src={logoHeader}
        className="mark"
        alt="logo manufacturing masters"
      />
    </Container>
  )
}

export default Logo
