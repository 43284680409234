import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { t as translate } from 'react-multi-lang'

import { BackgroundContainer, MainContainer, InfoContainer, FormContainer, ImgLogo, Form, InputDiv, InputStyle, CheckboxDiv, CheckboxText, SignButton, OrangeText } from './styled'

import logoMark from '../../../assets/images/EMC_Black.png'
import api from '../../../services/api'
import useAuth from '../../../hooks/useAuth'
import { toast } from 'react-toastify'
import ReactGA from 'react-ga4'

const Register = () => {
  ReactGA.initialize('G-FHPNEK8D8D')
  const [email, setEmail] = useState('')
  const [mobile, setMobile] = useState('')
  const [password, setPassword] = useState('')
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [company, setCompany] = useState('')
  const [province, setProvince] = useState('')
  const [terms, setTerms] = useState(false)
  const [buttonDisabled, setButtonDisabled] = useState(false)

  const { signIn } = useAuth()

  async function handleSubmit (e: any) {
    e.preventDefault()
    setButtonDisabled(true)

    if (!firstName && !lastName && !company && !province && !email && !mobile && !password) {
      toast.error('All fields are required')
      setButtonDisabled(false)
      return
    }

    if (!firstName) {
      toast.error('First Name field is required')
      setButtonDisabled(false)
      return
    }

    if (!lastName) {
      toast.error('Last Name field is required')
      setButtonDisabled(false)
      return
    }

    if (!company) {
      toast.error('Company field is required')
      setButtonDisabled(false)
      return
    }

    if (!province) {
      toast.error('Province field is required')
      setButtonDisabled(false)
      return
    }

    if (!email) {
      toast.error('Email field is required')
      setButtonDisabled(false)
      return
    }

    if (!mobile) {
      toast.error('Phone field is required')
      setButtonDisabled(false)
      return
    }

    if (!password) {
      toast.error('Password field is required')
      setButtonDisabled(false)
      return
    }

    if (!terms) {
      toast.error('Must agree with Terms to proceed.')
      setButtonDisabled(false)
    }

    const { data }: any = await api.postMethod('v4/register', {
      name: firstName,
      last_name: lastName,
      email,
      mobile,
      company,
      province,
      password,
      is_super_user: 0,
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
    })

    if (data.success) {
      ReactGA.event({
        category: 'User',
        action: 'sign-up',
        label: 'Account created successfully',
        value: 75
      })
      localStorage.setItem('userId', data.data.user_id)
      localStorage.setItem('accessToken', data.data.token)
      localStorage.setItem('userType', data.data.user_type)
      localStorage.setItem('push_status', data.data.push_status)
      localStorage.setItem('username', data.data.name)
      localStorage.setItem('active_profile_id', data.data.sub_profile_id)
      localStorage.setItem('active_profile_image', data.data.picture)
      localStorage.setItem('active_profile_name', data.data.name)
      signIn(email, password)
      toast.success(data.message)
    } else toast.error(data.error_messages)

    setButtonDisabled(false)
  }

  return (
    <BackgroundContainer>
      <MainContainer>
        <InfoContainer>
          {/* <InfoItems>
            <InfoNumber>1</InfoNumber>
            <InfoText>
              Free 14 day trial. <br/>Cancel anytime. <br/> $149 / month after trial.
            </InfoText>
          </InfoItems>
          <InfoItems>
            <InfoNumber>2</InfoNumber>
            <InfoText>
              Unlimited access to 500+, tactical, best practice, videos, delivered by manufacturing experts.
            </InfoText>
          </InfoItems>
          <InfoItems>
            <InfoNumber>3</InfoNumber>
            <InfoText>
              Ability to sign-up 4 additional staff and share videos amongst your entire team.
            </InfoText>
          </InfoItems> */}
        </InfoContainer>
        <FormContainer>
          <Form onSubmit={handleSubmit}>
            <Link to="/login">
              <ImgLogo src={logoMark} />
            </Link>
            <div style={{ color: 'black', fontSize: '18px', fontWeight: 'bold' }}>
              Start 14 Days free-trial
            </div>
            <InputDiv>
              <InputStyle
                type="text"
                onChange={text => setFirstName(text.target.value)}
                id="firstName"
                name="firstName"
                placeholder="First Name"
                value={firstName}
              />
            </InputDiv>
            <InputDiv>
              <InputStyle
                type="text"
                onChange={text => setLastName(text.target.value)}
                id="lastName"
                name="lastName"
                placeholder="Last Name"
                value={lastName}
              />
            </InputDiv>
            <InputDiv>
              <InputStyle
                type="text"
                onChange={text => setCompany(text.target.value)}
                id="company"
                name="company"
                placeholder="Company"
                value={company}
              />
            </InputDiv>
            <InputDiv>
              <InputStyle
                type="text"
                onChange={text => setProvince(text.target.value)}
                id="province"
                name="province"
                placeholder="Province"
                value={province}
              />
            </InputDiv>
            <InputDiv>
              <InputStyle
                type="email"
                onChange={text => setEmail(text.target.value)}
                id="email"
                name="email"
                placeholder="E-mail"
                value={email}
              />
            </InputDiv>
            <InputDiv>
              <InputStyle
                type="text"
                onChange={text => setMobile(text.target.value)}
                id="mobile"
                name="mobile"
                placeholder="Phone"
                value={mobile}
              />
            </InputDiv>
            <InputDiv>
              <InputStyle
                type="password"
                onChange={text => setPassword(text.target.value)}
                id="pwd"
                name="password"
                placeholder="Password"
                value={password}
              />
            </InputDiv>
            <CheckboxDiv>
              <input
                type="checkbox"
                id="chkTerm"
                name="agreeTerms"
                onChange={text => setTerms(!terms)}
                value={+terms}
              />
               <CheckboxText>
                I agree with the  <OrangeText><a href="https://www.manufacturing-masters.com/termsanddconditionsemc" target="_blank" rel="noreferrer"> terms</a></OrangeText>
              </CheckboxText>
            </CheckboxDiv>
            <SignButton
              disabled={buttonDisabled}>
              {
              buttonDisabled
                ? 'Loading...'
                : translate('signup')
              }
            </SignButton>
            <CheckboxText>
              Already have an account? <Link to={'/login'}> < OrangeText> Sign In Now</OrangeText> </Link>
            </CheckboxText>
          </Form>
        </FormContainer>
      </MainContainer>
      {/* <FooterContainer>
        <div>500+ Videos in our growing training library.</div>
        <div>Continuous Improvement - Strategy and Planning - Design and Engineering - Finance and Reporting - Health and Safety.</div>
        <div>Human resources and Recruitment - Internet of things - Smart factories - Additive manufacturing.</div>
        <div>IT and Cyber Security - Logistics - Marketing - Sales - Shop Floor - Supply Chain - Job Descriptions - Global Development - Wellness.</div>
      </FooterContainer> */}
    </BackgroundContainer>
  )
}

export default Register
