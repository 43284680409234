/* eslint-disable camelcase */
import React, { useEffect, useState } from 'react'
import { t } from 'react-multi-lang'
import Header from '../../../../components/Auth/Header'
import { SuggestionVideo } from '../../../../components/Auth/Header/Search'
import Item from '../../../../components/Auth/SliderView/MainSlider/Item'
import Slider from '../../../../components/Auth/SliderView/MainSlider/Slider'
import { ButtonOrange } from '../../../../components/Buttons'
import Loading from '../../../../components/Loading'
import api from '../../../../services/api'

import {
  ContainerCastCrew
} from './styled'

export type Expert = {
  category_id: number
  created_at: Date
  description: string
  email: string
  id: number
  image: string
  name: string
  status: number
  sub_categories: string
  sub_category_id: number
  unique_id: string
  updated_at: Date
}

const Home = (props: any) => {
  const [castCrew, setCastCrew] = useState<Expert | null>(null)
  const [videos, setVideos] = useState<SuggestionVideo[]>([])

  function chunkArray (myArray: any[], size: number) {
    const results = []

    while (myArray.length) {
      results.push(myArray.splice(0, size))
    }

    return results
  }

  async function getExperts () {
    const { data }: any = await api.postMethod('v4/cast_crews/videos', { cast_crew_id: props.match.params.id, skip: 0 })
    setCastCrew(data.details_cast_crew)
    let videoExpert = []

    if (process.env.REACT_APP_ENV === 'PROD') {
      videoExpert = data.data.map((movie: any) => {
        return movie.in_production && movie
      }).filter((m: any) => m)
    } else {
      videoExpert = data.data.map((movie: any) => {
        return movie.in_test && movie
      }).filter((m: any) => m)
    }

    const result: any = chunkArray(videoExpert, 5)
    setVideos(result)
  }

  useEffect(() => {
    getExperts()
  }, [props.match.params.id])

  if (!castCrew) {
    return <Loading />
  }

  return (
    <>
      <Header />
      <ContainerCastCrew>
        <h1>{castCrew?.name}</h1>
        <div className="row mt-3">
          <div className="col-md-9">
            <div dangerouslySetInnerHTML={{ __html: castCrew?.description as string }} />
          </div>
          <div className="col-md-1" />
          <div className="col-md-2 d-flex flex-column">
            <img className="img-fluid" src={castCrew?.image} alt={`${castCrew?.name}`} />
            <ButtonOrange href={`mailto:${castCrew?.email}`} target="_blank" rel="noopener noreferrer" className="btn mt-3">{t('contact_expert')}</ButtonOrange>
          </div>
        </div>
      </ContainerCastCrew>

      {videos.map((res: any) => (
        <Slider key={res.index}>
          {res.map((movie: SuggestionVideo, i: number) => (
            <Item movie={movie} key={movie.admin_video_id} index={i}>
              item1
            </Item>
          ))}
        </Slider>
      ))}
    </>
  )
}

export default Home
