import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { t as translate } from 'react-multi-lang'

import useAuth from '../../../hooks/useAuth'
import { Button, ContainerLogin, Form, Image, Input, CheckboxDiv, CheckboxText, OrangeText } from './styled'

import logoMark from '../../../assets/images/EMC_Icon_White.png'
import { toast } from 'react-toastify'

const Login = (props: any) => {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [loadingContent, setLoadingContent] = useState('')
  const [buttonDisabled, setButtonDisabled] = useState(false)
  const [terms, setTerms] = useState(false)

  const { signIn } = useAuth()

  useEffect(() => {
    const paramsInObject: any = { email: null, password: null }
    props.location.search.substr(1).split('&').forEach((param: any) => (paramsInObject[param.split('=')[0]] = param.split('=')[1]))
    if (paramsInObject.email) {
      setEmail(paramsInObject.email)
      setPassword(paramsInObject.password)
    }
  }, [])

  function handleSubmit (e: any) {
    e.preventDefault()
    setLoadingContent(translate('button_loading'))
    setButtonDisabled(true)
    if (terms) signIn(email, password)
    else toast.error('you have to accept the terms of use')
    setLoadingContent('')
    setButtonDisabled(false)
  }

  function handleGuestUser () {
    const loginGuest: string = (process.env.REACT_APP_GUEST_LOGIN as string)
    const passGuest: string = (process.env.REACT_APP_GUEST_PASS as string)

    setLoadingContent(translate('button_loading'))
    setButtonDisabled(true)
    signIn(loginGuest, passGuest)
    setLoadingContent('')
    setButtonDisabled(false)
  }

  return (
    <ContainerLogin>
      {/* <img className='bg-img' src="https://emc-emc-emc.s3.sa-east-1.amazonaws.com/EMC_Background.png" alt="Backgroung-img" /> */}
      <Link to="/login">
        <Image
          src={logoMark}
          className="site-logo"
          alt="logo_img"
        />
      </Link>
      <div className="container mx-auto">
        <Form className="col-md-6 mx-auto" onSubmit={handleSubmit}>
          <br />
          <div className="form-group">
            <Input
              type="email"
              onChange={text => setEmail(text.target.value)}
              className="form-control"
              id="email"
              name="email"
              placeholder="Email"
              value={email}
            />
          </div>
          <div className="form-group mt-2">
            <Input
              type="password"
              onChange={text => setPassword(text.target.value)}
              className="form-control"
              id="pwd"
              name="password"
              placeholder="Password"
              value={password}
            />
          </div>
          <CheckboxDiv>
              <input
                type="checkbox"
                id="chkTerm"
                name="agreeTerms"
                onChange={text => setTerms(!terms)}
                value={+terms}
              />
              <CheckboxText>
                I agree with the  <OrangeText><a href="https://www.manufacturing-masters.com/termsanddconditionsemc" target="_blank" rel="noreferrer"> terms</a></OrangeText>
              </CheckboxText>
            </CheckboxDiv>
          <Link to={'/forgot-password'} className="forgot-password mt-4 mb-2">
            {translate('forgot_password')}
          </Link>
          <Button
            className="btn p-3"
            disabled={buttonDisabled}
          >
            {
              loadingContent === null
                ? loadingContent
                : translate('signin')
            }
          </Button>
          <Button
            className="btn p-3"
            disabled={buttonDisabled}
            onClick={handleGuestUser}
          >
            {
              loadingContent === null
                ? loadingContent
                : translate('guestAccess')
            }
          </Button>
          <span>
            {translate('new_to_website')}{' '}
            <Link to={'/register'} className="signup-link">
              {translate('sign_up_now')}
            </Link>
          </span>
        </Form>
      </div>
      {/* <footer className="mt-5">
        <span className="footer-copywriter-brand"><a href="https://www.comuniverse.com.br/" className="link-poweredby">{translate('poweredBy')}</a><sup>&copy;</sup></span>
      </footer> */}
    </ContainerLogin>

  )
}

export default Login
